import { graphql } from "gatsby";
import * as React from "react";
import Gallery from "@browniebroke/gatsby-image-gallery";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const Page = ({ data, location }) => {
  const images = data.images.edges.map(({ node }, idx) => ({
    ...node.childImageSharp,
    // Generate name based on the index as caption.
    caption: `אולטרה עוטף עזה 2017 | תמונה ${idx + 1}`,
  }));

  // Override some of Lightbox options to localise labels in French
  const lightboxOptions = {
    // imageLoadErrorMessage: "Impossible de charger cette image",
    nextLabel: "תמונה הבאה",
    prevLabel: "תמונה קודמת",
    closeLabel: "סגירה",
  };

  const title = "תמונות מירוץ אולטרה עוטף עזה 2017";
  return (
    <Layout path={location.pathname} title={title}>
      <SectionContent title={title}>
        <Gallery images={images} lightboxOptions={lightboxOptions} />
      </SectionContent>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "ultra-2017" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
